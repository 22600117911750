.admin-table th{
    font-size: 14px;
    color: white !important;
    font-weight: 600;
    background-color: #B38D2E !important;
}

.admin-table td{
    font-family: 'Barlow';
    font-size: 12px;
    color:black;
    align-items: center;
}

.super-admin-table th{
    font-size: 14px;
    color: white !important;
    font-weight: 600;
    background-color: #00B67A !important;
}

.super-admin-table td{
    font-family: 'Barlow';
    font-size: 12px;
    color:black;
    align-items: center;
}


.card-body h4{
    color:#04394F;
    font-size: 18px;
    font-weight: 600;
}

strong{
    font-size: 16px;
    font-weight: 500;
}

.custom-card-body p{
    font-size: 14px;
    font-family: 'Barlow';
}
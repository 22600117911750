@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Montserrat", "Barlow" -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  scroll-behavior: smooth;
  overflow-x: hidden;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Montserrat{
  font-family: "Montserrat",sans-serif;
}

.Barlow{
  font-family: "Barlow",sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat",sans-serif;
}

.form-label{
  font-size: 14px;
  font-family: "Montserrat";
  font-weight:500;

}

.btn{
  background-color: #B38D2E !important;
  border: 0px solid transparent !important;
  font-family: "Montserrat",sans-serif !important;
  font-weight: 500 !important;
  height: 48px !important;
}

.btn:hover{
  background-color: #f1b00c !important;
  color: white;
  font-weight: bold;
  transition: all ease-out;
}

.form-control{
  border: 1px solid #04394F !important;
  box-shadow: none !important;  /* Remove the focus shadow */

}

.form-check .form-check-input {
  border: 1px solid #04394F !important; /* Custom border color */
  box-shadow: none !important; /* Remove inner shadow */
}

.form-control:not(textarea){
  height: 48px !important;
}

.form-control:focus {
  border: 2px solid !important;
  border-color: #04394F !important;  /* Keep the border color consistent on focus */
  box-shadow: none !important;       /* Disable the shadow on focus */
  outline: none !important;          /* Disable the default outline on focus */
}

a{
  color: #B38D2E !important;
  font-size: 16px;
  font-family: 'Barlow';
}

.form-select{
  border: 1px solid #04394F !important;
  color: rgba(0, 0, 0, 0.35); /* Optional: change placeholder color for better contrast */
  font-size: 12px !important; /* Set font size for the select element */
  font-family: 'Montserrat',sans-serif !important;
  padding-left: 10px !important;
  height: 48px !important;
}

.form-select:focus {
  border: 2px solid !important;
  border-color: #04394F !important;  /* Keep the border color consistent on focus */
  box-shadow: none !important;       /* Disable the shadow on focus */
  outline: none !important;          /* Disable the default outline on focus */
}

.form-select option{
  font-size: 12px; /* Make the placeholder text smaller */
  font-weight: 400;
}

.form-control::placeholder {
  font-size: 12px; /* Make the placeholder text smaller */
  font-weight: 400;
  color: rgba(0, 0, 0, 0.35); /* Optional: change placeholder color for better contrast */
  font-family: 'Montserrat',sans-serif;
}

/* Custom scrollbar styles */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px; /* Adjust width as needed */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #E3D8BB; /* Light blue color */
  border-radius: 10px; /* Round the corners of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Light gray background for the track */
  border-radius: 10px; /* Round the corners of the track */

}

.custom-scrollbar::-webkit-scrollbar-corner {
  background-color: #f0f0f0; /* Background color for the corner (optional) */
}


.client-table th{
    font-size: 14px;
    color: white !important;
    font-weight: 600;
    background-color: #04394F !important;
}

.client-table td{
    font-family: 'Barlow';
    font-size: 12px;
    color:black;
    align-items: center;
}


.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.offcanvas .offcanvas-body .nav-link {
    padding: 10px 20px;
    transition: background-color 0.3s ease;
}

.offcanvas .offcanvas-body .nav-link:hover {
    background-color: #f4a261;
    color: white;
}

/* Sidebar in mobile mode */
.sidebar-mobile {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 250px;
    z-index: 1040;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
}

.sidebar-mobile.show {
    display: block;
    transform: translateX(0);
}

/* Sidebar content styling */
.sidebar-content {
    height: 100%;
    padding: 1rem;
}

/* Overlay for closing the sidebar on mobile */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1030;
}

.email-verification-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  
  .spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .message-container {
    animation: fadeIn 0.8s ease-in-out;
  }
  
  .success-message {
    color: green;
    font-size: 1.5rem;
  }
  
  .error-message {
    color: red;
    font-size: 1.5rem;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  